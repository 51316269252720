
import { defineComponent, reactive, ref, toRaw, RendererElement } from 'vue';
import { CloseCircleOutlined } from '@ant-design/icons-vue';
import { useI18n } from 'vue-i18n';
import scrollIntoView from 'scroll-into-view-if-needed';
import { validateInfos } from '@ant-design-vue/use/es/useForm';
import RepositoryForm from './repository-form.vue';
import TaskForm from './task-form.vue';
import MemberList from './member-list.vue';

import FooterToolbar from '@/components/base-layouts/footer-toolbar/index.vue';

export interface ErrorItem {
  key: string;
  message?: string;
  fieldLabel?: string;
}

const fieldLabels: Record<string, string> = {
  name: '仓库名',
  url: '仓库域名',
  owner: '仓库管理员',
  approver: '审批人',
  dateRange: '生效日期',
  type: '仓库类型',
  name2: '任务名',
  url2: '任务描述',
  owner2: '执行人',
  approver2: '责任人',
  dateRange2: '生效日期',
  type2: '任务类型',
};

export default defineComponent({
  name: 'AdvanceForm',
  setup() {
    const { t } = useI18n();
    const state = reactive({
      errors: [] as ErrorItem[],
    });

    const repositoryForm = ref<RendererElement | null>(null);
    const taskForm = ref<RendererElement | null>(null);

    // generator errors
    const errorList = (errs: validateInfos) => {
      state.errors = Object.keys(toRaw(errs)).map(key => ({
        key: key,
        message: errs[key].help,
        fieldLabel: fieldLabels[key],
      }));
    };

    const handleSubmit = (e: Event) => {
      e.preventDefault();
      Promise.all([repositoryForm.value?.validate(), taskForm.value?.validate()])
        .then(() => {})
        .catch((err: Error) => {
          console.error('handleSubmit', err);
          const errs: validateInfos = Object.assign(
            {},
            repositoryForm.value?.validateInfos,
            taskForm.value?.validateInfos,
          );
          errorList(errs);
        });
    };
    const handleErrorClick = (key: string) => {
      const node = document.querySelector(`[data-validate-id=${key}]`);

      if (node) {
        scrollIntoView(node, {
          scrollMode: 'if-needed',
          block: 'center',
        });
      }
    };

    return {
      t,
      state,
      repositoryForm,
      taskForm,
      handleErrorClick,
      handleSubmit,
    };
  },
  components: {
    RepositoryForm,
    TaskForm,
    MemberList,

    FooterToolbar,
    // icons
    CloseCircleOutlined,
  },
});
